import * as React from "react";

class BlocTitle extends React.Component {
  render() {
    return (
      <h1 className={this.props.styleName && `${this.props.styleName}`}>
        {this.props.content || this.props.children}
      </h1>
    );
  }
}

export default BlocTitle;
