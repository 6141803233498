import * as React from "react";

class BlocContent extends React.Component {
  render() {
    const { className, content, animate, list } = this.props;
    console.log(this.props.animate);
    return (
      <div
        data-aos={animate ? animate : "fade-left"}
        className={` mx-auto text-xs bloc-content${
          list === true ? "-list" : " lg:text-base"
        } ${className}`}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    );
  }
}

export default BlocContent;
